<template>
  <div>
    <header-top-dashboard
      primary
      :custom-right-content="true"
      :show-back-button="true"
    >
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <v-card-text class="card-padding">
              <v-form>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Product Details</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Product Name</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Product Name"
                      v-model="product.name"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.product.name.$errors)
                      "
                      @blur="v$.product.name.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Product Grouping</label
                    >
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Product Grouping"
                      :items="productGroups"
                      item-text="name"
                      item-value="id"
                      v-model="product.productGroupingId"
                      required
                      :error-messages="
                        getFieldValidationErrors(
                          v$.product.productGroupingId.$errors
                        )
                      "
                      @blur="v$.product.productGroupingId.$touch"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Product Status</label
                    >
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Product Status"
                      :items="productStatuses"
                      item-text="value"
                      item-value="key"
                      v-model="product.status"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.product.status.$errors)
                      "
                      @blur="v$.product.status.$touch"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Cost Price</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Cost Price"
                      v-model="product.costPrice"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.product.costPrice.$errors)
                      "
                      @blur="v$.product.costPrice.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Retail Price</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Retail Price"
                      v-model="product.retailPrice"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.product.retailPrice.$errors)
                      "
                      @blur="v$.product.retailPrice.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Manufacturer</label
                    >
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Manufacturer"
                      :items="manufacturers"
                      item-text="name"
                      item-value="id"
                      v-model="product.manufacturerId"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Barcode</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Bar Code"
                      v-model="product.barcode"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Package Units</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Package Units"
                      v-model="product.packageUnits"
                      required
                      :error-messages="
                        getFieldValidationErrors(
                          v$.product.packageUnits.$errors
                        )
                      "
                      @blur="v$.product.packageUnits.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Unit Weight</label
                    >
                    <v-row>
                      <v-col>
                        <v-text-field
                          outlined
                          class="input-style font-size-input text-light-input placeholder-light"
                          placeholder="Package Weight"
                          v-model="product.weight"
                          required
                          :error-messages="
                            getFieldValidationErrors(v$.product.weight.$errors)
                          "
                          @blur="v$.product.weight.$touch"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          outlined
                          class="input-style font-size-input text-light-input placeholder-light"
                          placeholder="Measure"
                          :items="weightMeasures"
                          item-text="key"
                          item-value="value"
                          v-model="product.weightMeasure"
                          required
                          :error-messages="
                            getFieldValidationErrors(
                              v$.product.weightMeasure.$errors
                            )
                          "
                          @blur="v$.product.weightMeasure.$touch"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Stock on Hand</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Stock on Hand"
                      v-model="product.stockOnHand"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.product.stockOnHand.$errors)
                      "
                      @blur="v$.product.stockOnHand.$touch"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Images</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="3" md="3" class="py-0">
                    <v-card class="card-shadow mb-6">
                      <div class="overlay" @click="showFileSelector('primary')">
                        <v-icon color="#ff5722" size="12" class="overlay-icon">
                          ni-palette
                        </v-icon>
                        <v-img
                          height="200"
                          :src="product.images.primary.imageUrl"
                        ></v-img>
                      </div>

                      <v-card-title>
                        <p
                          style="
                            width: 100%;
                            text-align: center;
                            font-size: medium;
                          "
                        >
                          Primary Image
                        </p>
                      </v-card-title>
                    </v-card>
                  </v-col>

                  <v-col cols="3" md="3" class="py-0">
                    <v-card class="card-shadow mb-6">
                      <div
                        class="overlay"
                        @click="showFileSelector('secondary')"
                      >
                        <v-icon color="#ff5722" size="12" class="overlay-icon">
                          ni-palette
                        </v-icon>
                        <v-img
                          height="200"
                          :src="product.images.secondary.imageUrl"
                        ></v-img>
                      </div>
                      <v-card-title>
                        <p
                          style="
                            width: 100%;
                            text-align: center;
                            font-size: medium;
                          "
                        >
                          Secondary Image
                        </p>
                      </v-card-title>
                    </v-card>
                  </v-col>

                  <v-col cols="3" md="3" class="py-0">
                    <v-card class="card-shadow mb-6">
                      <div
                        class="overlay"
                        @click="showFileSelector('tertiary')"
                      >
                        <v-icon color="#ff5722" size="12" class="overlay-icon">
                          ni-palette
                        </v-icon>
                        <v-img
                          height="200"
                          :src="product.images.tertiary.imageUrl"
                        ></v-img>
                      </div>
                      <v-card-title>
                        <p
                          style="
                            width: 100%;
                            text-align: center;
                            font-size: medium;
                          "
                        >
                          Tertiary Image
                        </p>
                      </v-card-title>
                    </v-card>
                  </v-col>

                  <v-col cols="3" md="3" class="py-0">
                    <v-card class="card-shadow mb-6">
                      <div
                        class="overlay"
                        @click="showFileSelector('quaternary')"
                      >
                        <v-icon color="#ff5722" size="12" class="overlay-icon">
                          ni-palette
                        </v-icon>
                        <v-img
                          height="200"
                          :src="product.images.quaternary.imageUrl"
                        ></v-img>
                      </div>
                      <v-card-title>
                        <p
                          style="
                            width: 100%;
                            text-align: center;
                            font-size: medium;
                          "
                        >
                          Quaternary Image
                        </p>
                      </v-card-title>
                    </v-card>
                  </v-col>

                  <!-- <v-col v-if="product.images.length == 0">
                    <p>This product has no images.</p>
                  </v-col>
                  <v-col
                    v-else
                    v-for="(image, index) in product.images"
                    :key="index"
                    cols="12"
                    md="3"
                    class="py-0"
                  >
                    <v-card class="card-shadow mb-6">
                      <v-img height="200" :src="image.fullImageUrl"></v-img>
                    </v-card>
                  </v-col> -->
                  <input
                    @change="uploadImage"
                    ref="fileUploadInput"
                    id="fileUploadInput"
                    type="file"
                    hidden
                  />
                </v-row>
                <!-- <v-file-input
                  v-model="fileToUpload"
                  @change="addNewImage"
                  label="Upload new image"
                  outlined
                  dense
                >
                </v-file-input> -->
                <v-btn
                  @click="submit"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  :loading="submitButton.isLoading"
                  class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                  color="#5e72e4"
                  >Update</v-btn
                >
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import useVuelidate from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";

export default {
  name: "products-edit",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    HeaderTopDashboard,
  },
  validations() {
    return {
      product: {
        name: { required },
        retailPrice: { required },
        costPrice: { required, numeric },
        // type: { required },
        packageUnits: { required, numeric },
        weight: { required, numeric },
        weightMeasure: { required },
        stockOnHand: { required, numeric },
        status: { required },
        productGroupingId: { required },
      },
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fileToUpload: null,
      fileToUploadDesignation: "",
      product: {
        id: 0,
        name: "",
        retailPrice: "",
        discountPrice: "",
        costPrice: "",
        type: "",
        barcode: "",
        packageUnits: "",
        weight: "",
        weightMeasure: "",
        stockOnHand: "",
        status: "",
        productGroupingId: null,
        manufacturerId: null,
        images: {
          primary: {
            file: null,
            imageUrl: require("@/assets/img/placeholder-200x200.png"),
          },
          secondary: {
            file: null,
            imageUrl: require("@/assets/img/placeholder-200x200.png"),
          },
          tertiary: {
            file: null,
            imageUrl: require("@/assets/img/placeholder-200x200.png"),
          },
          quaternary: {
            file: null,
            imageUrl: require("@/assets/img/placeholder-200x200.png"),
          },
        },
      },
      productGroups: [],
      manufacturers: [],
      submitButton: {
        isLoading: false,
      },
    };
  },
  computed: {
    ...mapStores(useSessionStore),
  },
  methods: {
    addNewImage() {
      const formData = new FormData();
      formData.append("file", this.fileToUpload);
      formData.append("name", this.fileToUpload.name);

      const config = {
        ...this.sessionStore.getHttpConfig,
      };

      config.headers["Content-Type"] = "multipart/form-data";

      this.$http
        .post(`/products/${this.id}/images`, formData, config)
        .then(() => {
          this.fileToUpload = null;
          this.loadData();
          this.$notify.success("Image uploaded successfully!");
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while uploading the image. Please try again later or contact support."
          );
        });
    },

    getFieldValidationErrors(errors) {
      const messages = [];

      for (let i = 0; i < errors.length; i++) {
        messages.push(`${errors[i].$message}`);
      }

      return messages;
    },

    loadData() {
      this.$http
        .get(`/products/${this.id}`, this.sessionStore.getHttpConfig)
        .then((result) => {
          const product = result.data.data[0];

          this.product.id = product.id;
          this.product.name = product.name;
          this.product.retailPrice = product.retail_price;
          this.product.discountPrice = product.discount_price;
          this.product.costPrice = product.cost_price;
          this.product.type = product.type;
          this.product.barcode = product.barcode;
          this.product.packageUnits = product.package_units;
          this.product.stockOnHand = product.stock_on_hand;
          this.product.status = product.status;
          this.product.productGroupingId = product.group.id;
          this.product.manufacturerId = product.manufacturer_id;
          // this.product.category = product.category;
          this.product.manufacturer = product.manufacturer;

          try {
            let weightNumber = product.weight;
            weightNumber = weightNumber.match(/[+-]?([0-9]*[.])?[0-9]+/g)[0];

            const indexOfWeightNumber = product.weight.indexOf(weightNumber);
            const weightMeasure = product.weight.substr(
              indexOfWeightNumber + weightNumber.length
            );

            this.product.weight = weightNumber;
            this.product.weightMeasure = weightMeasure.toLowerCase();
          } catch (error) {
            this.$notify.warning("Encountered invalid product weight values.");
          }

          for (let i = 0; i < product.images.length; i++) {
            if (product.images[i].designation == "primary") {
              this.product.images.primary.imageUrl = `${this.sessionStore.getApiHost}${product.images[i].image_uri}`;
            } else if (product.images[i].designation == "secondary") {
              this.product.images.secondary.imageUrl = `${this.sessionStore.getApiHost}${product.images[i].image_uri}`;
            } else if (product.images[i].designation == "tertiary") {
              this.product.images.tertiary.imageUrl = `${this.sessionStore.getApiHost}${product.images[i].image_uri}`;
            } else if (product.images[i].designation == "quaternary") {
              this.product.images.quaternary.imageUrl = `${this.sessionStore.getApiHost}${product.images[i].image_uri}`;
            }
          }
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while loading the delivery details. Please try again later or contact support."
          );
        });
    },

    async submit() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        this.$notify.warning(
          "Please resolve the validation errors before proceeding!"
        );
        return;
      }

      this.submitButton.isLoading = true;

      const productBody = {
        name: this.product.name,
        retail_price: this.product.retailPrice,
        discount_price: this.product.discountPrice,
        cost_price: this.product.costPrice,
        type: this.product.type,
        package_units: this.product.packageUnits,
        weight: `${this.product.weight}${this.product.weightMeasure}`,
        stock_on_hand: this.product.stockOnHand,
        status: this.product.status,
        product_grouping_id: this.product.productGroupingId,
        manufacturer_id: this.product.manufacturerId,
      };

      if (this.product.barcode) {
        productBody.barcode = this.product.barcode;
      }

      this.$http
        .patch(
          `/products/${this.id}`,
          productBody,
          this.sessionStore.getHttpConfig
        )
        .then(async () => {
          this.$notify.success("Product updated successfully!");
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while updating the product. Please try again later or contact support."
          );
        })
        .finally(() => {
          this.submitButton.isLoading = false;
        });
    },

    showFileSelector(designation) {
      this.fileToUploadDesignation = designation;

      const fileInputElement = this.$refs.fileUploadInput;
      fileInputElement.click();
    },

    uploadImage(event) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", file.name);
      formData.append("designation", this.fileToUploadDesignation);

      const config = {
        ...this.sessionStore.getHttpConfig,
      };

      config.headers["Content-Type"] = "multipart/form-data";

      this.$http
        .post(`/products/${this.id}/images`, formData, config)
        .then(() => {
          this.fileToUpload = null;
          this.loadData();
          this.$notify.success("Image uploaded successfully!");

          this.fileToUploadDesignation = "";
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while uploading the image. Please try again later or contact support."
          );
        });
    },
  },
  mounted() {
    this.loadData();

    this.$http
      .get("/product-groups?size=100", this.sessionStore.getHttpConfig)
      .then((result) => {
        const productGroups = result.data.data;

        this.productGroups.splice(0);
        for (let i = 0; i < productGroups.length; i++) {
          this.productGroups.push(productGroups[i]);
        }
      })
      .catch((error) => {
        this.handleHttpError(
          error,
          "An error occurred while loading the product groups. Please try again later or contact support."
        );
      });

    this.$http
      .get("/manufacturers?size=100", this.sessionStore.getHttpConfig)
      .then((result) => {
        const manufacturers = result.data.data;

        this.manufacturers.splice(0);
        for (let i = 0; i < manufacturers.length; i++) {
          this.manufacturers.push(manufacturers[i]);
        }
      })
      .catch((error) => {
        this.handleHttpError(
          error,
          "An error occurred while loading the manufacturers. Please try again later or contact support."
        );
      });
  },
};
</script>
